import React, { useEffect, useState } from "react";
import styled from "styled-components";
import newsletters from "../../data/newsletters.json";
import NewsletterBigCard from "../components/cards/newsletterBigCard";
import NewsletterCard from "../components/cards/newsletterCard";
import CookieBanner from "../components/CookieBanner.js";
import DynamicGrid from "../components/dynamicGrid";
import Hero from "../components/hero";
import Layout from "../components/layout";
import PopupWrapper from "../components/popupWrapper";
import Section from "../components/Section";
import SEO from "../components/seo";
import TeamMember from "../components/teamMember";
import Testimonials from "../components/testimonials";
import range from "../utils/range";
import shuffle from "../utils/shuffle";

if (typeof window !== "undefined") {
  // Make scroll behavior of internal links smooth
  require("../../utils/smooth-scroll.polyfills.min.js")('a[href*="#"]');
}

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndexPage = () => {
  const featuredNewsletters = newsletters.newsletters.filter(n => n.featured);
  const [nonFeaturedNewsletters, setNonFeaturedNewsletters] = useState(newsletters.newsletters.filter(n => !n.featured));
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [newsletterFormVisibility, setNewsletterFormVisibility] = useState(false);

  useEffect(() => {
    // local storage
    let otherNewslettersCount = localStorage.getItem("home:other_newsletters_count");
    let otherNewsletterOrdering = localStorage.getItem("home:other_newsletters_ordering");

    // invalidate saved ordering if newsletter count changes
    if (otherNewslettersCount && otherNewsletterOrdering && parseInt(otherNewslettersCount) !== nonFeaturedNewsletters.length) {
      otherNewslettersCount = null;
      otherNewsletterOrdering = null;
    }

    let ordering;

    if (otherNewsletterOrdering) {
      // read the saved ordering
      ordering = otherNewsletterOrdering.split(",").map(n => parseInt(n));
    } else {
      // generate a new random ordering and save it
      ordering = shuffle(range(0, nonFeaturedNewsletters.length));
      localStorage.setItem("home:other_newsletters_count", `${nonFeaturedNewsletters.length}`);
      localStorage.setItem("home:other_newsletters_ordering", ordering.join(","));
    }

    // sort the newsletters based on the ordering
    const sortedOtherNewsletters = [];
    for (const index of ordering) {
      sortedOtherNewsletters.push(nonFeaturedNewsletters[index]);
    }

    // set the newsletters
    setNonFeaturedNewsletters(sortedOtherNewsletters);
  }, []);

  return (
    <Layout darkbg>
      <SEO title="Unreadit - The best Reddit content in your inbox" card="generic" />

      <CookieBanner />

      <PopupWrapper
        show={newsletterFormVisibility}
        onOutsideClick={() => {
          setNewsletterFormVisibility(false);
        }}
      >
        {selectedNewsletter && (
          <NewsletterBigCard
            newsletter={selectedNewsletter}
            floating
            dark
            formOnScreen={newsletterFormVisibility}
            closeClicked={() => {
              setNewsletterFormVisibility(false);
            }}
          />
        )}
      </PopupWrapper>

      <Hero />
      <Section id="newsletters" centered wide>
        <DynamicGrid baseWidth="25em">
          {featuredNewsletters.map((n, index) => {
            return (
              <NewsletterCard
                curated={true}
                key={index}
                order={-n.mailbrew_data.subscribers_count}
                newsletter={n}
                onButtonClick={() => {
                  setNewsletterFormVisibility(true);
                  setSelectedNewsletter(n);
                }}
              />
            );
          })}
        </DynamicGrid>
      </Section>
      <Testimonials />
      <Section
        title="Discover more topics with our automated newsletters"
        description="We’ve built a system to fetch the very best Reddit content and generate automated newsletters."
        centered
        wide
      >
        <DynamicGrid baseWidth="22em">
          {nonFeaturedNewsletters.map((n, index) => (
            <NewsletterCard
              curated={false}
              key={index}
              newsletter={n}
              onButtonClick={() => {
                setNewsletterFormVisibility(true);
                setSelectedNewsletter(n);
              }}
            />
          ))}
        </DynamicGrid>
      </Section>
      <Section centered title="Made by">
        <DynamicGrid baseWidth="18em" maxWidth="48em">
          <TeamMember
            name="Fabrizio Rinaldi"
            username="linuz90"
            teamRole="Designer and Filmmaker"
            subreddits="/r/mildlyinteresting /r/PraiseTheCameraman /r/MemeEconomy /r/shittymoviedetails"
          />
          <TeamMember
            name="Francesco Di Lorenzo"
            username="frankdilo"
            teamRole="Full-Stack Developer"
            subreddits="/r/LifeProTips /r/NonononoYes /r/EvilBuildings /r/AskReddit"
          />
        </DynamicGrid>
      </Section>
    </Layout>
  );
};

export default IndexPage;
