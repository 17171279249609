import React from "react";
import styled from "styled-components";
import colors from "./colors";
import P1 from "./styled/P1";
import P2 from "./styled/P2";

const TestimonialWrapper = styled.a`
  width: 100%;
  /* max-width: 44em; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  border-radius: 0.8em;
`;

const Avatar = styled.img`
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.4em;
  p {
    flex: 0 0 auto;
  }
`;

const TestimonialCard = props => {
  const { name, username, company, quote } = props;

  return (
    <TestimonialWrapper target="_blank">
      {/* href={`https://twitter.com/${username}`} */}
      <P1 dark style={{ fontSize: "1.2em" }}>
        {quote}
      </P1>
      <Author>
        <Avatar src={`../images/avatars/${name}.jpg`} />
        <P2>
          {name}, {company}
        </P2>
      </Author>
    </TestimonialWrapper>
  );
};

export default TestimonialCard;
