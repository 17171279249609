/**
 * Range from start to end (non-inclusive).
 * @param {*} start Start of the range (included in the range)
 * @param {*} end End of the range (excluded from the ragne)
 */
export default function range(start, end) {
  var ans = [];
  for (let i = start; i < end; i++) {
    ans.push(i);
  }
  return ans;
}
