import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import H2 from "../styled/H2";
import colors from "../colors";
import Button from "../button";
import svgSprites from "../svgSprites";
import P1 from "../styled/P1";
import kFormatter from "../../../utils/kFormatter";
import P2 from "../styled/P2";
import PoweredByMailbrew from "../PoweredByMailbrew";

const NewsletterCardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  min-height: 2em;
  background: #ffffff;
  border: 1px solid rgba(226, 226, 229, 0.6);
  box-shadow: 0px 4px 20px -5px rgba(147, 160, 187, 0.15);
  border-radius: 10px;
  padding: 1.4em 1.8em 2em;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.2s;
  :hover {
    box-shadow: 0px 5px 50px -10px rgba(147, 160, 187, 0.5);
    > div:first-of-type > svg {
      opacity: 0.4;
    }
  }
`;

const SvgBg = styled.div`
  display: block;
  position: absolute;
  top: 0em;
  right: 0em;
  width: ${props => (props.small ? "6em" : "8em")};
  height: ${props => (props.small ? "6em" : "8em")};
  pointer-events: none;
  svg {
    opacity: 0.15;
    transition: 0.2s;
    width: 100%;
    height: 100%;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  align-self: stretch;
  margin-top: 1.6em;
  a,
  div {
    width: 100%;
  }
`;

const linkStyle = {
  color: colors.accent,
  marginBottom: "1em",
  fontSize: "1.2em"
};

const NewsletterCard = props => {
  const { onButtonClick, curated } = props;

  // to avoid warning
  const wrapperProps = { ...props };
  delete wrapperProps.onButtonClick;

  const { title, slug, mailbrew_data, brew_url } = props.newsletter || {};

  let subtitle = mailbrew_data.subscribers_count > 10 ? `${kFormatter(mailbrew_data.subscribers_count)} Subscribers` : "New!";

  if (props.special) {
    subtitle = "Special";
  }

  return (
    <NewsletterCardWrapper small={!curated} style={{ order: props.order }} {...wrapperProps}>
      <SvgBg small={!curated}>{svgSprites.newsletters[slug]}</SvgBg>
      <H2 style={{ margin: 0 }} left>
        <a href={brew_url} target="_blank">
          {title}
        </a>
      </H2>
      {curated && (
        <P2 accent style={{ margin: ".5em 0 .6em", opacity: 0.5 }}>
          {subtitle}
        </P2>
      )}
      <P1 style={{ marginBottom: ".8em" }}>{mailbrew_data.description}</P1>
      <div style={{ flex: "1 0 auto" }} />
      <Buttons>
        <Button label="Preview" tertiary href={brew_url} />
        <Button label="Subscribe" onClick={onButtonClick} />
      </Buttons>
    </NewsletterCardWrapper>
  );
};

export default NewsletterCard;
