import React from "react";
import styled from "styled-components";
import H4 from "./styled/H4";
import colors from "./colors";

const TeamMemberWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border-radius: 0.8em;
  padding: 1.5em 1em 0.5em;
  transition: 0.2s;
  :hover {
    background: ${colors.lighter};
  }
`;

const Avatar = styled.img`
  width: 4em;
  height: 4em;
  border-radius: 50%;
  margin-bottom: 0.3em;
`;

const Role = styled.p`
  font-size: 1.1em;
  text-align: center;
  color: ${colors.accent};
  margin: 0;
`;

const SubredditsList = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  text-align: center;
  color: ${colors.grey};
  margin: 0.4em 0;
  span {
    display: inline-block;
    padding: 0.2em 0.3em;
    font-size: 1.1em;
  }
`;

const TeamMember = props => (
  <TeamMemberWrapper href={`https://twitter.com/${props.username}`} target="_blank">
    <Avatar src={`../images/team/${props.name}.jpg`} />
    <H4 style={{ marginBottom: ".1em" }} dark>
      {props.name}
    </H4>
    <Role>{props.teamRole}</Role>
    {props.subreddits && (
      <SubredditsList>
        {props.subreddits.split(" ").map((sub, index) => (
          <span key={index}>{sub}</span>
        ))}
      </SubredditsList>
    )}
  </TeamMemberWrapper>
);

export default TeamMember;
