import React from "react";
import styled from "styled-components";
import FlyingUpvotes from "./flyingUpvotes";
import Section from "./Section";
import H1 from "./styled/H1";
import H2 from "./styled/H2";
import colors from "./colors";
import svgSprites from "./svgSprites";
import P2 from "./styled/P2";
import { Link } from "gatsby";

const HeroGroup = styled.div`
  min-height: 24em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 640px) {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
  }
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  line-height: 1.6;
  font-weight: 500;
  text-align: center;
  color: ${colors.dark};
  margin: 0 0 0.4em;
  max-width: 24em;
`;

const Banner = styled.a`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  text-decoration: none;
  padding: 6px 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  p {
    margin: 0 0 0 4px;
  }
  * {
    color: hsl(210, 3%, 40%) !important;
  }
  transition: 0.1s;
  :hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
`;

const Hero = () => {
  return (
    <Section centered>
      <FlyingUpvotes />

      <HeroGroup>
        {svgSprites.logo.logo2}
        <H1>Reddit-fueled Weekly Newsletters.</H1>
        <Subtitle>
          Join <span style={{ color: colors.accent }}>8,000+</span> readers, and get the very best content on your
          favorite topics with these digests.
        </Subtitle>
        <br />
        <Banner href="https://mailbrew.com/?utm_source=unreadit" target="_blank">
          <MailbrewLogo />
          <P2>Powered by Mailbrew</P2>
        </Banner>
      </HeroGroup>
    </Section>
  );
};

const MailbrewLogo = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.8297 4.98223C6.22954 4.84741 8.25104 4.72656 11 4.72656C13.7854 4.72656 15.824 4.85063 17.2257 4.98761C18.3891 5.1013 19.2349 6.02772 19.277 7.24116C19.3103 8.19978 19.3359 9.44364 19.3359 11C19.3359 12.5164 19.3116 13.7361 19.2796 14.6844C19.2376 15.9287 18.355 16.8898 17.15 17.007C15.7177 17.1463 13.6773 17.2734 11 17.2734C8.35787 17.2734 6.33594 17.1496 4.90674 17.0125C3.67811 16.8946 2.76987 15.9024 2.72497 14.6264C2.69071 13.6526 2.66406 12.4342 2.66406 11C2.66406 9.52859 2.69211 8.28436 2.72765 7.29844C2.77254 6.0532 3.64295 5.09654 4.8297 4.98223Z"
        stroke="#F75858"
        stroke-width="1.54688"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.675 4.27692C18.3564 4.42692 18.934 4.79441 19.3492 5.30378C19.3357 5.46085 19.2779 5.62099 19.1694 5.76893C17.9883 7.37917 15.4449 10.437 11.5394 13.0534C11.2118 13.2729 10.785 13.2765 10.4529 13.0639C6.12866 10.2964 3.7478 7.27958 2.70793 5.71749C2.64734 5.62647 2.60552 5.53189 2.58093 5.43707C3.01037 4.85435 3.6362 4.43237 4.38386 4.2702C5.82465 4.10738 7.9852 3.95312 11 3.95312C14.0561 3.95312 16.2345 4.11165 17.675 4.27692Z"
        fill="#F75858"
      />
    </svg>
  );
};

export default Hero;
