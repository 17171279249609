import React from "react";
import styled from "styled-components";

const PopupWrapperWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: ${props => (props.show ? "auto" : "none")};
  z-index: 3;
  will-change: opacity;
  > div:last-child {
    max-width: 54em;
    transition: transform 0.25s cubic-bezier(0.2, 0, 0.2, 1);
    transform: ${props => (props.show ? "scale(1)" : "scale(.98)")};
    will-change: transform;
  }
  @media (max-width: 640px) {
    overflow: scroll;
    display: block;
    padding: 0;
    > div {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
  ${props =>
    props.position == "top" &&
    `
    align-items: flex-start;
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
`;

const PopupWrapper = props => {
  const { show, position = "center", onOutsideClick } = props;

  return (
    <PopupWrapperWrapper show={show} position={position}>
      <Backdrop onClick={onOutsideClick} />
      {props.children}
    </PopupWrapperWrapper>
  );
};

export default PopupWrapper;
