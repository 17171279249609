import React from "react";
import styled, { keyframes } from "styled-components";

const moveX = keyframes`
  100% {
    background-position-x: -100em;
  }
`;

const UpvotesWrapper = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(10em) translateY(-15em) rotate(24deg);
  height: 24em;
  width: 120em;
  /* border: 1px solid red; */
  background: url("../images/hero/upvotes.svg");
  background-size: 100em auto;
  background-repeat: repeat-x;
  background-position-y: center;
  background-position-x: 0;
  animation: ${moveX} 60s linear infinite;
  z-index: -1;
  opacity: 0.07;
  pointer-events: none;
`;

const FlyingUpvotes = props => {
  return (
    <UpvotesWrapper
      style={{
        backgroundPosition: "0px center"
      }}
    />
  );
};

export default FlyingUpvotes;
