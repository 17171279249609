import React from "react";
import styled from "styled-components";
import Section from "./Section";
import TestimonialCard from "./testimonialCard";

const testimonials = [
  {
    name: "Kevin Kelly",
    username: "kevin2kelly",
    company: "Recomendo",
    quote:
      "The curators of Unreadit pull all the best content from related subreddits and send you an email once a week. I’ve spent zero hours rummaging through Reddit in the last month because of this."
  },
  {
    name: "Vincent Denise",
    username: "yesnoornext",
    company: "Threader",
    quote: "Unreadit is an elegant way to explore niche subreddits and receive the most relevant content."
  },
  {
    name: "Manuel Frigerio",
    username: "mnlfrgr",
    company: "Maître",
    quote:
      "You can spend countless hours on Reddit or you can be smart and subscribe to one weekly digest on Unreadit. I no doubt prefer the latter."
  },
  {
    name: "Ryan Hoover",
    username: "rrhoover",
    company: "Product Hunt",
    quote: "Clever way to bootstrap a newsletters network."
  }
];

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 2em;
  justify-content: flex-end;
`;

const Testimonials = props => {
  return (
    <Section wide id="testimonials">
      <TestimonialsGrid>
        {testimonials.map((t, index) => (
          <TestimonialCard key={index} name={t.name} username={t.username} company={t.company} quote={t.quote} />
        ))}
      </TestimonialsGrid>
    </Section>
  );
};

export default Testimonials;
